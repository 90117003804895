

<template>
	<div class="news-detail">
		<NewsHeader :data="data"></NewsHeader>
		<NewsInfo :data="info"></NewsInfo>
	</div>
</template>

<script>
	import NewsHeader from '@/components/news/NewsHeader.vue';
	import NewsInfo from '@/components/news/NewsInfo.vue';
	import {mapState} from 'vuex'
	import { 
		getArticleInfo,
	} from '@/api/store.js'
	export default {
		name: "enteringHuifa",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		components:{
			NewsHeader,
			NewsInfo
		},
		data() {
			return {
				id:this.$route.params.id,
				type:'',
				data:{
					name:'证券之窗',
					name2:'详情',
					url:'/investment/external/4'
				},
				info:{}
				
			}
		},
		created() {
			this.getArticleInfoFun()
			this.data.name=this.lang.invest_bond_window
			this.data.name2=this.lang.huifa_detail
		},
        beforeRouteUpdate(to, from, next) {
            this.id = to.params.id
			console.log( this.id,' to.params.id')
            next();
        },
		methods:{
			path(routePath){
				 this.$router.push(routePath); 
			},
			getArticleInfoFun(){
				// 获取分类
                let data = {
                    id: this.id,
                }
				getArticleInfo(data).then(res=>{
					this.info=res.data.article_info
					// this.typeList = [...this.typeList, ...res.data]
				})
			},
		}
		
	}
</script>

<style lang='less'>
	.news-detail{
		background: linear-gradient(to bottom, #FFE0E0, #F2F2F2),#F2F2F2;
		background-repeat: no-repeat;
		background-size: 100% 25rem;
		padding-bottom: 8rem;
	}
	
</style>